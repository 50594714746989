import { defineStore } from "pinia";
import { stringify } from "qs";
import type { Location } from "~/types/strapi/api/location/content-types/location/location";
import type { Payload } from "~/types/strapi/common/schemas-to-ts/Payload";

type LocationAttributesWithoutImage = Omit<Location["attributes"], "image">;
export type StoredLocation = {
  id: Location["id"];
  attributes: LocationAttributesWithoutImage;
};
type LocationsRecord = Record<string, StoredLocation>;

export const useLocationsStore = defineStore("locations", () => {
  const locations = ref<LocationsRecord>({});

  async function fetchLocationBySlug(slug: string | null): Promise<void> {
    if (slug === null) {
      return;
    }

    if (locations.value[slug] !== undefined) {
      return;
    }

    const query = stringify(
      {
        filters: {
          slug: {
            $eqi: slug,
          },
        },
        populate: {
          address: {
            populate: true,
          },
        },
      },
      {
        encodeValuesOnly: true,
      },
    );

    const { data } = await useApiFetch<Payload<StoredLocation[]>>(`/api/locations?${query}`);
    const location = data.value?.data[0];

    if (location === undefined) {
      return;
    }

    locations.value[slug] = location;
  }

  async function getLocationBySlug(slug: string): Promise<StoredLocation> {
    const location = locations.value[slug];

    if (location === undefined) {
      await fetchLocationBySlug(slug);
      const location = locations.value[slug];

      if (location === undefined) {
        console.error(`There is no location for slug "${slug}" in strapi set.`);
      }

      return location;
    }

    return location;
  }

  function $reset(): void {
    locations.value = {};
  }

  return {
    $reset,
    getLocationBySlug,
  };
});
